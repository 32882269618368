import React from 'react'
import NavbarTwo from '../components/Navbar/NavbarTwo';
import { Footer } from '../components/Footer/Footer';
import { ContactForm } from '../components/Contact Form/ContactForm'
// import { Wrapper } from '../Wrapper';

export default function Contact() {
    return (
        <div>
            <NavbarTwo />
            <ContactForm />
            <Footer />
        </div>
    )
}
