import axios from 'axios';
import React, { useState } from 'react'
import './ContactForm.scss';
import { AiOutlineClose } from 'react-icons/ai';

export function ContactForm() {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [showAlertMessage, setShowAlertMessage] = useState(false);

    const resetFields = () => {
        setName('')
        setEmail('')
        setPhone('')
        setMessage('')
    }

    const updateShowAlertMessage = (message) => {
        setAlertMessage(message)
        setShowAlertMessage(true)
    }

    const hideAlert = () => {
        setAlertMessage('')
        setShowAlertMessage(false)
    }

    const submitForm = (e) => {
        e.preventDefault();
        if(name === '' || message === ''|| email === ''){
            updateShowAlertMessage('Please provide a Name, Email Address, and Message')
        } else {
            axios.post("https://formsubmit.co/ajax/trentungard@gmail.com", {
                name: name,
                email: email,
                phone: phone,
                message: message
            })
                .then((res) => { 
                    resetFields()
                    updateShowAlertMessage('Success! Form input received :)')
                })
                .catch((e) => {
                    console.error(e)
                    updateShowAlertMessage('Error submitting form. Please check console or try again shortly')
                })
        }
    }

    return (
        <div className="contact-form-container">
            <form onSubmit={(e) => submitForm(e)}>
                <h2>Contact Me</h2>
                <div className="alert-indicator" style={{display: showAlertMessage ? 'flex' : 'none', position: 'relative'}}>
                    <AiOutlineClose 
                        size={20} 
                        style={{position: 'absolute', top: '10px', right: '10px', cursor: 'pointer'}}
                        onClick={() => hideAlert()}
                    />
                    <p>{alertMessage}</p>
                </div>
                <p>To get in touch with me, feel free to fill out the form below. If your message warrants a response, I'll get back to you as soon as I can!</p>
                <div className="column section">
                    <label for="name">Name: </label>
                    <input 
                        type="text" 
                        id="name" 
                        name="name" 
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div className="row section">
                    <div>
                        <label for="email">Email:</label>
                        <input 
                            type="email" 
                            id="email" 
                            name="name" 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div>
                        <label for="phone">Phone:</label>
                        <input 
                            type="phone" 
                            id="phone" 
                            name="phone" 
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </div>
                </div>
                <div className="column section">
                    <label for="message">Message:</label>
                    <textarea 
                        id="message" 
                        name="message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                </div>
                <button 
                    className="submit-button"
                    type="submit"
                >Submit</button>
            </form>
        </div>
    )
}
